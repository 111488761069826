#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiperW {
  // width: 100%;
  // padding-top: 45% ;
  padding-bottom: 100% !important;
  // height: 100%;
  // background-color: red !important;
}


@media only screen and (min-width: 360px) {
  .swiperW {
    width: 100%;
    padding-top: 23%;
    padding-bottom: 100%;
    // height: 100%;
  }

}

@media only screen and (min-width: 375px) {
  .swiperW {
    width: 100%;
    padding-top: 0%;
    padding-bottom: 100%;
    // height: 100%;
  }

}
@media only screen and (min-width: 390px) {
  .swiperW {
    // width: 100%;
    padding-top: 42%;
    padding-bottom: 100%;
    // height: 100%;
  }

}
@media only screen and (min-width: 414px) {
  .swiperW {
    // width: 100%;
    padding-top: 40%;
    padding-bottom: 100%;
    // height: 100%;
  }

}

@media only screen and (min-width: 412px) {
  .swiperW {
    // width: 100%;
    padding-top: 46%;
    padding-bottom: 100%;
    // height: 100%;
  }

}

@media only screen and (min-width: 769px) {
  .swiperW {
    // width: 100%;
    padding-top: 45%;
    padding-bottom: 100%;
    // height: 100%;
  }

}

.swiper-pagination{
  bottom: 72% !important
}



.swiper-pagination-bullet-active {
  background-color: #4BD1C6 ;
  color: #4BD1C6 ;

  width: 10px ;
        height: 10px ;

}
.swiper-pagination-bullet {
  background-color: #8cd1cb ;
  width: 10px ;
        height: 10px ;

}


@media only screen and (min-width: 530px) {
  
  .swiper-pagination{
    bottom: 65% !important
  }

}



@media only screen and (min-width: 414px) and (max-width: 416px) {
  
  .swiper-pagination{
    bottom: 67% !important
  }

}

// @media only screen and (min-width: 410px) and (max-width: 420px) {
  
//   .swiper-pagination{
//     bottom: 64% !important
//   }

// }
@media only screen and (min-width:500px)  {
  
  .swiper-pagination{
    bottom: 64% !important
  }

}

@media only screen and (min-width: 420px) and (max-width: 440px) {
  
  .swiper-pagination{
    bottom: 67% !important
  }

}
// @media only screen and (min-width: 418px) {
  
//   .swiper-pagination{
//     bottom: 65% !important
//   }

// }


.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.swiper-slide img {
  display: block;
  width: 65%;
}

@media only screen and (min-width: 769px) {
  .swiperW {
    width: 100%;
    padding-top: 10%;
    padding-bottom: 50%;
  }
  .swiper-slide:first-child {
    transition: transform 100ms;
  }

  .swiper-slide:first-child img {
    transition: box-shadow 500ms;
  }

  .swiper-slide.swiper-slide-active:first-child {
    // transform: translateX(50%);
    z-index: 2;
  }

  .swiper-slide.swiper-slide-active:first-child img {
    box-shadow: 0px 32px 80px rgba(0, 0, 0, 0.35);
  }

  .swiper-slide:nth-child(2) {
    transition: transform 100ms;
  }

  .swiper-slide.swiper-slide-next:nth-child(2) {
    transform: translateX(55%);
    z-index: 1;
  }

  .swiper[dir="rtl"] .swiper-slide.swiper-slide-active:first-child {
    transform: translateX(-50%);
  }

  .swiper[dir="rtl"] .swiper-slide.swiper-slide-next:nth-child(2) {
    transform: translateX(-55%);
  }
}

@import "Direction.scss";

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
.getStarted-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 65%;
  justify-content: center;
  border-radius: 40px;
  background-image: linear-gradient(to right, #192238, #000000);
  height: fit-content;
  min-height: 250px;
  align-items: center;
}
.results {
  width: 250px;
  height: 120px;
  border-radius: 15px;
  background-color: #27314c;
  margin: 20px;
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.FAQ {
  direction: initial;
  background-color: #192238;
}
.Affiliate {
  direction: initial;
}

.Avatar {
  cursor: pointer;
}

.big-image {
  border-radius: 50%;
}
.rty-free {
  img {
    margin: auto;
    height: 200px;
  }
}
.logo-header {
  img {
    width: 26px;
  }
}
.footer-img {
  img {
    width: 30px;
    height: 30px;
    margin-top: 6px;
  }
}





@media screen and (max-width: 500px) {
  .footer-img {
    img {
      margin-top: 4px;
    }
  }
}
.Humble {
  img {
    height: 100px;
    object-fit: contain;
    width: 25%;
    margin: auto;
    cursor: pointer;
  }
}
.HumbleAN{
  img {
    height: 100px;
    object-fit: contain;
    width: 40%;
    margin: auto;
    cursor: pointer;
  } 
}
.Humble-1 {
  img {
    height: 100px;
    object-fit: contain;
    width: 35%;
    margin: auto;
    cursor: pointer;
  }
}
.Humble-2 {
  img {
    height: 100px;
    object-fit: contain;
    width: 60%;
    margin: auto;
    cursor: pointer;
  }
}
.media {
  cursor: pointer;
  img {
    width: 25px;
    height: 25px;
  }
}
@media only screen and (max-width: 600px) and (min-width: 300px)  {
  .css-o93abw {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: var(--chakra-space-8);
    background-color: #E5E5E5;
    justify-items: center;
    flex-wrap: wrap
    
  }
  .css-r30ywd {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 50%;
    flex: auto;
}

.css-1jencmh {
  display: none;
}

.css-1p0rkue{color:#4FD1C5;font-size:var(--chakra-fontSizes-md);text-align:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-align-self:center;-ms-flex-item-align:center;align-self:center;font-weight:var(--chakra-fontWeights-bold);margin:10px;}
.css-1vzihco{
  margin:'10px';
  margin-bottom: '10px';
}

.css-1u747p9{object-fit:contain;-webkit-background-size:cover;background-size:cover;height:80px;width:50%;}




  }

.newImagetest {
 margin-left: 100px;
}
.newImagetest2 {
width: 200px;
// width: 220px;
// margin-left: 20px;
margin-top: 30px;
}
.newImagetest3 {
  width: 220px;
  // width: 220px;
  margin-left: -4px;
  // margin-left: 10px;
  margin-top: 10px;
  }

  .newImagetestheroshot{
    width: 320px;
    margin-left: -0px;
    margin-top: 0px;
  }

  .newImagetestheroshotMobile{
    width: 200px;
    margin-left: -0px;
    margin-top: 0px;
  }

  .vjs-paused .vjs-big-play-button { display: none; }

  .container {
    padding: 1px;
    // background: #419be0;
    // padding-left: 20px;
    // padding-right: 20px;

  }


  .slick-arrow, .slick-next, .slick-prev{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 30px;
    height: 30px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }
  
  .slick-slide img {
    margin: auto;
  }
  
  .slick-next:before, .slick-prev:before {
    font-size: 30px;
    line-height: 1;
    opacity: .75;
    color: #4bd1c6;}

    button.slick-next:before, button.slick-prev:before {
        font-size: 30px;
        line-height: 1;
        opacity: .75;
        color: #4bd1c6;}

        .colorContact{
          background-image:linear-gradient(90deg, rgba(25,34,56,1) 35%, rgba(0,0,0,1) 100%)
        }
       

        // .slick-dots li button:before, .slick-next:before, .slick-prev:before{
        //     font-size: 30px;
        //     line-height: 1;
        //     opacity: .75;
        //     color: #4bd1c6;
        // }

        
        // .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus{}
      


.spacingbet{
  margin-top: 25px;

}


.getStarted {
  img {
    width: 210px;
    height: 450px;
    margin: auto;
  }
}
.container {
  width: 400px;
  height: 400px;
}
.container {
  width: 90%;
  height: 90%;
  margin: auto;
  padding: 20px;
}
.containers {
  width: 100%;
  height: 100%;
}
.how-its-Work{
  height: 300px;
  margin: auto;
padding-top:5px;
width: 80%;
}
.how-its-Work-radar{
  height: 260px;
  margin: auto;
padding-top:5px;
width: 80%;
}
.chakra-select__icon-wrapper {
 @include rtl{
  right:17.5rem ;
}
}
.css-1iqbypn{
  @include rtl{
    right:28.5rem ;
  }
}


.input-center{
  .chakra-numberinput__field{
    text-align: center;
  }
}

 .chakra-portal{
   border-radius: 35px !important;
 }
 .rtl{
   direction: rtl;
   -webkit-box-direction: rtl;
 }
 .react-tel-input{
   .selected-flag{
     @include rtl{
       margin-right:5px
     }
     .arrow {
      @include rtl{
        left: 0;
       right:26px;
      }
      
     }
   }
 }  

 .react-tel-input {
   .form-control{
     @include rtl{
      
       direction: ltr;
     }
   }
 }

 .react-tel-input {
  .special-label {
    color:#000000
  }

   .form-control{
padding :10.5px 0px 10.5px 58px;
border: 1px solid #000000;

 }}

 .icon{
   margin: 5px 5px 0 5px ;
 }
 .css-1ddaykt >option, .css-1ddaykt >optgroup{
   background:white !important
 }
 .media-pay{
  img{
width:220px;
height:40px;
margin: auto;
  }
}
 @media only screen and (max-width: 600px) {
  .media-pay{
    img{
 width:205px;
 height:40px;
 margin: auto;
    }
  }
}
.tableScroll::-webkit-scrollbar {
  height: 6px;
  display: block;
}

.css-1d1gowr2{
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    width: 350px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: var(--chakra-radii-md);
    background: '#ffffff00';
    color: inherit;
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
    // z-index: var(--chakra-zIndices-modal);
    // box-shadow: var(--chakra-shadows-dark-lg);
}
.modaltest{
  background-color:'red';
}


.tableScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  display: block;
}

.tableScroll::-webkit-scrollbar-thumb {
  background-color: #4fd1c5;
  outline: 1px solid #4fd1c5;
  border-radius: 8px;
}

.card-text{
  @include rtl{
    direction: rtl;
   }
}
.coming{
  font-size:10px;
  font-weight:bold;
}

.downLTR{
  direction: ltr;
}
.rowrevv{
  flex-direction: row-reverse;
}
.padscan{
  padding-left: 10px;
}

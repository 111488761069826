@mixin rtl {
    .rtl & {
      @content;
    }
  }
  
  @mixin rtl-in {
    .rtl {
      @content;
    }
  }
  
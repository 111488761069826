/* Kyros
/* created by on3stepThemes


==================================================
Table of Content
================================================== 
* color
* font
* general
* preloader
* mouse
* menu
* Galery
* resume
* blog
* contact
* to top 
* footer
* new skills bar
* media all 
* menu reveal
*/


@charset "utf-8";

/* import main fonts */
@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*color*/
$white: #fff;
$gray: #bbb;
$black: #171A1D;
$general: #727272;
$black_soft: #333;
$black_col: #2e333c;
$black_more: #0f0f0f;
$color: #52d3cb;
$color_more: #52d3cb;

/*font*/
* {
    --body-font: "Nunito", Helvetica, Arial, sans-serif;
    --title-font: "Saira", Helvetica, Arial, sans-serif;
    --primary-color-rgb:207,31,31;
}

/*general*/
html {scroll-behavior: auto;}
body{
    font-family: var(--body-font);
    font-size: 16px;
    font-weight: 400;
    color: #909090;
    background: $black;
    line-height: 30px;
    padding: 0;
    line-height: 1.8em;
    word-spacing: 0px;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.col-white{
  color: $white;
}
.color{
  color: #52d3cb;
}
.transition{
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, footer.footer-light h5 {
  margin-top: 0;
  font-family: var(--title-font);
  font-weight: bold;
  color: #fff;
}
.h1_big {
  color: $white;
  position: relative;
  left: -3px;
  top: -10px;
  font-size: 64px;
  line-height: 70px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 42px;
}
.spacer-20 {
  width: 100%;
  height: 20px;
  display: block;
  clear: both;
}
.spacer-half {
  width: 100%;
  height: 15px;
  display: block;
  clear: both;
}
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.css-8u3ikk {
  overflow-y: scroll;
  -ms-overflow-style: none;
}
.css-8u3ikk::-webkit-scrollbar {
  display: none;
}

.tableScroll::-webkit-scrollbar {
  height: 8px;
  display: block;
}

.tableScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  display: block;
}

.tableScroll::-webkit-scrollbar-thumb {
  background-color: #4fd1c5;
  outline: 1px solid #4a9e96;
  border-radius: 8px;
}
.modaltest{
  background-color:'red';
}
.colortexthaveBuilsht{
  color: #00B050 !important;
  // font-size: 45px !important;
}


.barchartwidMobile{
  // width: 310px !important;
  justify-content: center;
  align-content: center;
  align-items: center;
  // margin-left: 250px;
}
.barchartwidMobileLarge{
  // width: 520px !important;
  justify-content: center;
  align-content: center;
  align-items: center;
  // margin-left: 250px;
}

.firstdivServ{
  margin-top: 30%;
}
.section5serv{
  margin-top: 80%;

}

.chartCenterEN{
  // margin-left: 10px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;

}
.chartCenter{
  // margin-right: 25px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
}

.colortexthaverelax12Mobile{
  color: #fff !important;
  font-size: 20px;
  margin-left: 10px;
}
.colortexthaverelax2Mobile{
  color: #4BD1C6 !important;
  font-size: 20px;
  margin-left: 10px;
}



.blockquoteMobile{

  font-family: var(--body-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  border: none;
  // border-left: solid 3px #333333;
  // padding-left: 15px;
}

.css-1d1gowr{
  display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    width: 350px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: var(--chakra-radii-md);
    background: var(--chakra-colors-gray-100);
    color: inherit;
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
    // z-index: var(--chakra-zIndices-modal);
    // box-shadow: var(--chakra-shadows-dark-lg);
}


.nextbutton{
  margin: auto;
  text-align: center;
  width: 100%;
  position: absolute;
  margin-left: 10%;
}

.globmarginmobile{
  // margin-top: 20%;
  // margin-bottom: -70%;
  margin-top: 5%;
  margin-bottom: -30%;
}

.boujee-textMobilestory{
  font-size: 35px;
  --bg-size: 300%;
  --color-one: rgb(43, 43, 43);
  --color-two: rgb(253, 253, 253);
  --color-three: rgb(122, 122, 122);


  font-family: lato;
  // font-size: clamp(3rem, 25vmin, 8rem);
  // font-size: clamp(50px, 8vw, 60px);

  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
}
.q-bigMobilestory{
  background-image: linear-gradient(60deg, #e6e6e6, #9a9791);
  background-clip: text;
  color: transparent;
  font-size: 40px;

}

.bitcoinmobile{
  margin-top: 10%;
  // position: absolute;
  //   bottom: 0px;
  //   right: 10px;
}
.firstMobile{
  margin-bottom: 30%;

}


.hithereMobile{
  text-align: center;

}

.trytocentertext{
  text-align: center;
  // margin-top: 70%;
  margin-top: 35%;
 

}

.trytocentertextpaddingnot{
  text-align: center;
  // margin-top: 70%;
  margin-top: 35%;
  font-family: var(--body-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  border: none;

}



.trytocentertextDou{
  text-align: center;
  // margin-top: 70%;
  margin-top: 50%;
}
.trytomargintopswip{
  // margin-top: 90%; 
  margin-top: 25%; 

}
.trytomargintopswipDOU{
  margin-top: 40%; 


}
.trytomargintopswipmoney{
  // margin-top: 90%; 
  margin-top: 15%; 

}

.colortexthaverelax2AR{
  color: #4BD1C6 !important;
  font-size: 20px !important;
  // margin-right: 10px;

}


.textMobileSizepaddingnot{
  font-size:25px;
  text-align: center;
  font-family: var(--body-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  border: none;

}


.textMobileSize{
  font-size:25px;
  text-align: center;

}
.textMobileSizeMobile{
  font-size: 25px;
}
.globMobileheader{
  margin-bottom: 50%;
}
.firstdivMobile{
  width: 98%;
}
.lottieCenter{
  text-align: -webkit-center
}

.textMobileSizeTier{
  font-size:30px;
  text-align: center;
  justify-content: center;
}

.section4mobile{
  font-size: 30px;
  margin-top: 20%;
}
.section4mobileserv{
  font-size: 30px;
  margin-top: 15%;

}
.colortexthaveActivatedMobile{
  color: #00B050 !important;
  font-size: 30px !important;
}









@media  (min-width: 1440px) and (max-width :2000px) {
 
  .nextbuttoninsidelargescreen {
    position: absolute;
    margin-left:7.5%;
    margin-top:12.8% ;
    // margin-top: 148px
  }


  .boujee-text{
  font-size: clamp(2rem, 20vmin, 8rem);

  }
}

@media (min-width: 1440px) and (max-width :1601px) {

  .boujee-text-mobile{
  font-size: 55px;

  }
  .boujee-text-mobile2{
    font-size: 50px;
    }
    .boujee-text-mobile3{
      font-size: 44px;
    
      }
}

@media screen and (min-width: 1400px) {
  .nextbuttoninsidelargescreen {
    position: absolute;
    margin-left:7.5%;
    margin-top:12.8% ;
    // margin-top: 148px
  }

  .taaymbot{
    font-size: 14px;
  }
}
@media  (min-width: 1440px) and (max-width: 1600px) {
  .barchartwid{
    width: 1000px !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: 200px;
  }
  .nextbuttoninsidelargescreen {
    position: absolute;
    margin-left:7.5%;
    margin-top:11.2% ;
    // margin-top: 148px
  }
  .nextbuttoninsiderightscreen {  
    margin-right:7.5%;
    margin-top:11.2% ;

}
.taaymbot{
  font-size: 14px;
}
}

@media  (min-width: 1210px) and (max-width: 1440px) {
  blockquote {
    font-family: var(--body-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4em;
    border: none;
    // border-left: solid 3px #333333;
    padding-left: 15px;
  }
  
  .nextbuttoninsidelargescreen {
    position: absolute;
    margin-left:7.5%;
    margin-top:8% ;
    // margin-top: 148px
  }
  .nextbuttoninsiderightscreen {  
    margin-right:7.5%;
    margin-top:7.8% ;}
}
// @media screen and (max-width: 1200px) {
//   .nextbuttoninsidelargescreen {
//     // position: absolute;
//     margin-left:7.5%;
//     // margin-bottom:0% ;
//     margin-top:0% ;

//     // margin-top: 148px
//   }
// }
@media (min-width: 1152px) and (max-width: 1200px){
  .nextbuttoninsidelargescreen {
    // position: absolute;
    margin-left:7.5%;
    margin-top:7.8% ;
    // margin-top: 148px
  }
  .nextbuttoninsiderightscreen {
    margin-right:7.5%;
    margin-top:7.8% ;

  }
}



@media (min-width: 1275px) and (max-width: 1350px){

 .boujee-text-mobile{
  font-size: 49px !important;
 }

 .boujee-text-mobile3{
  font-size: 40px !important;
 }
 .colortexthaverelax2{
  font-size: 40px !important;

 }

 .forincbar{ 
  font-size:40px !important;

 }
 .forinc{
  font-size:49px !important;
 }

 .barchartwid{
  width: 500px !important;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-left: 250px;
}

.space-border3 {
  width: 50%;
  height: 1px;
  position: relative;
  margin-bottom: 20px !important;
}
.forincpie{
  font-size:40px !important;
}
.textSizeforAnyfirstsec{
  font-size: 40px !important;;
}

.gradient-text {
  background-image: linear-gradient(60deg, #e6e6e6, #9a9791);
  background-clip: text;
  color: transparent;
  font-size: 40px !important;

}
.marginforpiechart{
  margin-left: -150px
}
.forlottemargin{

}

.colortexthaverelax2AR{
  color: #4BD1C6 !important;
  font-size: 34px !important;
  margin-right: 10px;

}

.colortexthaverelax2{
  color: #4BD1C6 !important;
  font-size: 50px;
  margin-left: 10px;
}

 
}

.fuckpiechart{
  z-Index: 9999;
  position: absolute;
  padding-top: 87px;
  padding-left: 114px;
}

.fuckpiechartStory{
  z-Index: 9999;
  position: absolute;
  padding-top: 87px;
  padding-left: 114px;
}


@media (min-width: 1200px) and (max-width: 1350px){

  blockquote {
    font-family: var(--body-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4em;
    border: none;
    // border-left: solid 3px #333333;
    padding-left: 15px;
  }
  


  .piechartl{
    width: 300px !important;
  }
 
  .pieleft{

  }

  .boujee-text-mobile{
    font-size: 49px !important;
   }
  
   .boujee-text-mobile3{
    font-size: 40px !important;
   }
   .colortexthaverelax2{
    font-size: 40px !important;
  
   }
  
   .forincbar{ 
    font-size:40px !important;
  
   }
   .forinc{
    font-size:49px !important;
   }
  
   .barchartwid{
    width: 500px !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: 250px;
  }
  
  .space-border3 {
    width: 50%;
    height: 1px;
    position: relative;
    margin-bottom: 20px !important;
  }
  .forincpie{
    font-size:40px !important;
  }
  .textSizeforAnyfirstsec{
    font-size: 40px !important;;
  }

  .downloadhedin{
    position: fixed;
    // font-family: Lato;
    font-family: Arial, Helvetica, sans-serif;
    width: 100vw;
    
    }
    .testurl64{
      visibility: hidden;
    }
  
  .gradient-text {
    background-image: linear-gradient(60deg, #e6e6e6, #9a9791);
    background-clip: text;
    color: transparent;
    font-size: 40px !important;
  
  }
  .marginforpiechart{
    margin-left: -150px
  }
  .forlottemargin{
  
  }

  .colortexthave{
    color: #00B050 !important;
    font-size: 50px !important;
    }

    .colortexthaveBuilsht{
      color: #00B050 !important;
      font-size: 45px !important;
    }
    .colortexthaveActivated{
      color: #00B050 !important;
      font-size: 60px !important;
    }
  .WCWC{
    font-size:50px !important;
  }
  
 }


@media (min-width: 1351px) and (max-width: 1500px){

  .piechartl{
    width: 400px !important;
  }
  .boujee-text-mobile{
   font-size: 49px !important;
  }
 
  .boujee-text-mobile3{
   font-size: 40px !important;
  }
  .colortexthaverelax2{
   font-size: 40px !important;
 
  }
 
  .forincbar{ 
   font-size:40px !important;
 
  }
  .forinc{
   font-size:49px !important;
  }
 
  .barchartwid{
   width: 500px !important;
   justify-content: center;
   align-content: center;
   align-items: center;
   margin-left: 250px;
 }
 
 .space-border3 {
   width: 50%;
   height: 1px;
   position: relative;
   margin-bottom: 20px !important;
 }
 .forincpie{
   font-size:40px !important;
 }

 .textSizeforAnyfirstsec{
  font-size: 45px !important;;
}

.long-short{
  font-size: 45px !important;
}
.colortexthave{
  color: #00B050 !important;
    font-size: 55px;
}
.colortexthaveBuilsht{
  color: #00B050 !important;
  font-size: 55px !important;
}

.colortexthaveActivated{
  color: #00B050 !important;
  font-size: 60px !important;

}

.colortexthaveprice{
  color: #00B050 !important;
    font-size: 55px;
}
.WCWC{
  font-size:50px !important;
}

.colortexthaverelax2AR{
  color: #4BD1C6 !important;
  font-size: 35px !important;
  margin-right: 10px;

}

  
 }
 

@media (min-width: 1501px) and (max-width: 1680px){

  .barchartwid{
    width: 1000px !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: 250px;
  }

  .testsidepie{
    width: 500px;
  }

  .piechartl{
    width: 400px !important;
  }
  .textSizeforAnyfirstsec{
    font-size: 45px !important;
  }
//   .colortexthaverelax2AR{
//   color: #4BD1C6 !important;
//   font-size: 35px !important;
//   margin-right: 10px;
//   margin-top: -1px;


// }
}
// @media (min-width: 1200px) and (max-width: 1275px){


// }

.colortexthaveActivated{
  color: #00B050 !important;
  font-size: 60px !important;

}




.nextbuttoninside {
  margin-left:7.5%;
  margin-top:3.8% ;
}
.nextbuttoninsideconfirm {
  margin-left:7.5%;
  margin-top:-1.2% ;
}
.nextbuttoninsiderightconfirm {
  margin-right:7.5%;
  margin-top:-1% ;
}
.nextbuttoninsideright {
  margin-right:7.5%;
  margin-top:3.8% ;
}
.css-vooc0a {
  padding-inline-start: var(--chakra-space-1);
  padding-inline-end: var(--chakra-space-1);
  text-align: center;
}

.conterBox{
  align-items: center;
    justify-content: center;
}

@media screen and (min-width: 900px) and (max-width: 1600px) {
  .example {
  // margin-top: '20%';
    border: 8px solid black;
    background: yellow;
    position: absolute;
    padding-top:'20%'
  }
}


.downwid {
width: '90%';
}

.font-loader {
  font-family: 'Chilanka';
}

.grad {
  background-image: linear-gradient(red, yellow);
}

.tableBorder{
  // background-image: linear-gradient(red, yellow);
  // border-top-left-radius: '20%';
  border-radius: 20px;
}
.upTrTable{
  position: absolute;
  // bottom:'35px' !important;
z-index: 99;

float: center; 
margin-top: -35px; 
// transform: rotate(180deg);
  // width:1020px ;
  border:none;

border-bottom: none !important;
    border-color:#0b101c !important
  }
 

 .dosome{
    // width:190px;
  border:none;
  border-bottom: none !important;
  border-color:#0b101c !important

}
.css-myy953{
border-color:#0b101c ;
}

.fuckpiecharttest{
  z-Index: 9999;
  position: absolute;
  padding-top: 87px;
  padding-left: 114px;
}
// @media screen and (max-width: 1549px) and (min-width: 1400px) {
//   .dosome {
//     // font-size: 50px;
//     // padding: 50px;
//     // border: 8px solid black;
//     // background: yellow;
//     width: 140px;
//   }
// }


// @media screen and (max-width: 1690px) and (min-width: 1550px) {
//   .dosome {
//     // font-size: 50px;
//     // padding: 50px;
//     // border: 8px solid black;
//     // background: yellow;
//     width: 160px;
//   }
// }

// @media screen and (max-width: 1920px) and (min-width: 1700px) {
//   .dosome {
//     // font-size: 50px;
//     // padding: 50px;
//     // border: 8px solid black;
//     // background: yellow;
//     width: 165px;
//   }
// }
// @media screen and (min-width: 1920px) {
//   .dosome {
//     // font-size: 50px;
//     // padding: 50px;
//     // border: 8px solid black;
//     // background: yellow;
//     width: 210px;
//   }
// }

// @media screen and (min-width: 320px){

// .dosome {
//     width: 167px;
// }
// }
@media screen and (max-width: 1450px) and (min-width: 1410px) {
  .piechartl{
    width: 350px !important;
  }
  .dosome {
    width: 132px;
  }
  .textlessthan{
    font-size: 10px;

  }

  
}


@media screen and (max-width: 1509px) and (min-width: 1451px) {
 
  .barchartwid{
    width: 1000px !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: 200px;
  }

  .piechartl{
    width: 350px !important;
  }
  .dosome {
    width: 138px;

  }
  .textlessthan{
    font-size: 10px;

  }
  .colortexthaverelax2{
    color: #4BD1C6 !important;
    font-size: 40px;
    margin-left: 10px;
  }
  .colortexthaverelax2AR{
    color: #4BD1C6 !important;
    font-size: 40px;
    margin-right: 10px;
  
  }
  .lastpiecharttext{
    font-size: 40px !important;

  }
}
@media screen and (max-width: 1550px) and (min-width: 1510px) {
  .piechartl{
    width: 300px !important;
  }
  .lastpiecharttext{
    font-size: 40px !important;

  }
  .dosome {
    width: 142px;
  }
  .textlessthan{
    font-size: 10px;

  }
  .colortexthave{
    color: #00B050 !important;
      font-size: 50px;
  }
  .colortexthaveBuilsht{
    color: #00B050 !important;
    font-size: 45px !important;
  }
  .colortexthaveActivated{
    color: #00B050 !important;
      font-size: 45px;
  }
  .WCWC{
    font-size:50px !important;
  }

  .colortexthaverelax2{
    color: #4BD1C6 !important;
    font-size: 40px ;
    margin-left: 10px;
  }
  .colortexthaverelax2AR{
    color: #4BD1C6 !important;
    font-size: 35px !important;
    margin-right: 10px;
  
  }
  
}

@media screen and (max-width: 1279px) and (min-width: 1001px) {
  .colortexthaverelax2AR{
    color: #4BD1C6 !important;
    font-size: 32px !important;
    margin-right: 10px;
  
  }

}

@media screen and (max-width: 1620px) and (min-width: 1550px) {
  .barchartwid{
    width: 1000px !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: 250px;
  }

  .piechartl{
    width: 350px !important;
  }

  .lastpiecharttext{
    font-size: 40px !important;

  }
  
  .dosome {
    // font-size: 50px;
    // padding: 50px;
    // border: 8px solid black;
    // background: yellow;
    width: 150px;
  }
  .textlessthan{
    font-size: 10px;

  }
}

@media screen and (max-width: 1700px) and (min-width: 1620px) {
  .piechartl{
    width: 400px !important;
  }
  .lastpiecharttext{
    font-size: 40px !important;

  }
  
  .dosome {
    // font-size: 50px;
    // padding: 50px;
    // border: 8px solid black;
    // background: yellow;
    width: 155px;
  }
  .textlessthan{
    font-size: 12px;

  }
  .colortexthaverelax2AR{
  color: #4BD1C6 !important;
  font-size: 35px !important;
  margin-right: 10px;
  margin-top: -1px;


}
}
.containerpiech{
  width: 100%;
}

#chartapex {
  max-width: 650px;
  margin: 10px auto;
}
#chartapex2 {
  max-width: 650px;
  margin: 10px auto;
}


@media screen and (max-width: 1799px) and (min-width: 1701px) {

  .testsidepie{
    width: 500px;
  }
  .piechartl{
    width: 400px !important;
  }
  .lastpiecharttext{
    font-size: 40px !important;

  }
  
  .dosome {
    // font-size: 50px;
    // padding: 50px;
    // border: 8px solid black;
    // background: yellow;
    width: 163.8px;
  }
  .textlessthan{
    font-size: 12px;

  }
  .colortexthaverelax2AR{
    color: #4BD1C6 !important;
    font-size: 40px !important;
    margin-right: 10px;
    margin-top: -1px;
  
  
  }

}
.downLTR{
  direction: ltr;
  
}
.rowrevv{
  flex-direction: row-reverse;
}
.padscan{
  padding-left: 10px;
}
.paddpx{
  padding-left: 5px;
}

.spacetrade{
  margin-left: 2px;
}

@media screen and (max-width: 1799px) and (min-width: 1750px) {
  .barchartwid{
    width: 1000px !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: 250px;
  }

  .piechartl{
    width: 400px !important;
  }
  .dosome {
    // font-size: 50px;
    // padding: 50px;
    // border: 8px solid black;
    // background: yellow;
    width: 175px;
  }
  .textlessthan{
    font-size: 12px;

  }
}

@media screen and (max-width: 1920px) and (min-width: 1800px) {
  .barchartwid{
    width: 1000px !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: 250px;
  }
  .piechartl{
    width: 400px !important;
  }
  .dosome {
    // font-size: 50px;
    // padding: 50px;
    // border: 8px solid black;
    // background: yellow;
    width: 177px;
  }
  .textlessthan{
    font-size: 12px;

  }
  .colortexthaverelax2AR{
    color: #4BD1C6 !important;
    font-size: 50px !important;
    margin-right: 10px;
  
  
  }
  .colortexthaverelax2 {
    color: #4BD1C6 !important;
    font-size: 50px !important;
    margin-left: 10px;
    margin-top: 10px;
  
}
}
@media screen and (min-width: 1920px) {

  .barchartwid{
    width: 1000px !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: 250px;
  }
  .piechartl{
    width: 400px !important;
  }
  .dosome {
    // font-size: 50px;
    // padding: 50px;
    // border: 8px solid black;
    // background: yellow;
    width: 191px;
  }
  .textlessthan{
    font-size: 12px;

  }
  .colortexthaverelax2AR{
    color: #4BD1C6 !important;
    font-size: 50px !important;
    margin-right: 10px;
    margin-top: -1px;
  
  
  }

  .colortexthaverelax2 {
    color: #4BD1C6 !important;
    font-size: 50px !important;
    margin-left: 10px;
    margin-top: 10px;

  
}
}

.tooglewe{
  justify-content: end;
}




@media screen and (min-width: 1240px) and (max-width: 1440px){
  .sizeresponse{
    font-size: 12px;
  }
  .sizeresponse2{
    font-size: 11px;
  }

}


@media screen and (min-width: 1441px) and (max-width: 1699px){
  .sizeresponse{
    font-size: 15px;
  }
}

/* preloader */
#mainpreloader{
  .preloader{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 9999999;
    -webkit-animation-duration: 1.6s;
    animation-duration: 1.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
     }
     @-webkit-keyframes fadeOut {
        0% {opacity: 1;}
        25% {opacity: 1;}
        75% {opacity: 1;}
        100% {opacity: 0;}
     }

     @keyframes fadeOut {
        0% {opacity: 1;}
        25% {opacity: 1;}
        75% {opacity: 1;}
        100% {opacity: 0;}
     }

     .fadeOut {
        -webkit-animation-name: fadeOut;
        animation-name: fadeOut;
     }
}
.mainpreloader {
  position: relative;
  animation: rotate 1s infinite linear;
  border: 3px solid rgba(28, 191, 194, 0.1);
  width: 100px;
  height: 100px;
  margin-right: -24px;
  border-radius: 999px;
}
.mainpreloader span {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 4px solid transparent;
  border-top: 4px solid rgba(26, 203, 200, 0.7);
  top: -3px;
  left: -3px;
  border-radius: 999px;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#routerhang{
  transform: unset !important;
  transform-origin: unset !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.burgermenu{
  width: 32px;
  height: 32px;
  padding: 4px;
  text-align: center;
  font-size: 20px;
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  color: $white;
  transition: .5s;
  &:hover{
    background: $white;
    color: $black;
    transition: .5s;
  }
  i{
    position: relative;
    top: -2px;
  }
}
// section, .section{
//   position: relative;
//   // padding: 90px 0;
//   width: 100%;
//   height: auto;
//   background: #171A1D;
//   background-size: cover;
//   background-position-y: center;
//   &.bg-bottom{
//     &:before{
//       // position: absolute;
//       bottom: 0px;
//       left: 0;
//       content: '';
//       width: 100%;
//       height: 50%;
//       // background-image: linear-gradient(rgba(23,26,29,0), rgba(23,26,29,1));
//       z-index: 1;
//     }
//   }
//   &.bg-top{
//     &:after{
//       position: absolute;
//       top: 0px;
//       left: 0;
//       content: '';
//       width: 100%;
//       height: 50%;
//       background-image: linear-gradient(rgba(23,26,29,1), rgba(23,26,29,0));
//       z-index: 1;
//     }
//   }
//   .react-parallax-bgimage {
//     object-fit: cover;
//     top: -150px;
//   }
// }
.py-5.position-relative{
  z-index: 2;
}
.modalmarg{
  
}

.lottecss{
  margin-bottom: 250px
}

.backgsame{
  background-image: linear-gradient(90deg, #091017, #091118)
}
.maincolorforstory{
  background-image: linear-gradient(180deg, #192137, #000000);

}
.sssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss{}
.noHover{
  pointer-events: none !important;
  cursor: not-allowed
}
.Underglobmarginmobile{

}
.spacer-single {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}
.spacer-double {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}
.v-center {
  min-height: 100vh;
  // display: flex;
  align-items: center;
  justify-content: center;
  padding-top:50px ;
}
.v-center2 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top:50px ;
}
.firstdiv{

}

.list_location, .list_location li {
  list-style: none;
  padding: 0;
}
.list_location li {
  color: #bbbbbb;
  display: inline-block;
  margin-right: 35px;
  font-size: 14px;
  border-left: solid 1px rgba(0, 0, 0, .25);
  border-left-color: rgba(255, 255, 255, .25);
  padding-left: 10px;
  span {
    font-family: var(--title-font);
    color: $white;
    font-size: 16px;
    font-weight: bold;
    display: block;
  }
}
.float-text {
  width: 20px;
  position: fixed;
  z-index: 1002;
  margin-left: 30px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 12px;
  top: 50%;
  left: 15px;
  opacity: 1;
  transform: translate(-50%, -50%);
   span {
    margin-left: -5px;
    margin-top: 20px;
    writing-mode: vertical-rl;
    letter-spacing: .75px;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    color: rgba(255, 255, 255, .5);
  }
  .de_social-icons{
    span.buton{
      position: relative;
      left: 1px;
      top: 1px;
      color: $color;
      cursor: pointer;
      margin-top: 0;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
}
/*mouse*/
.mouse {
  position: absolute;
  width: 22px;
  height: 42px;
  bottom: 90px;
  left: 50%;
  margin-left: -12px;
  border-radius: 15px;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, .25);
  -webkit-animation: intro 1s;
  animation: intro 1s;
  z-index: 2;
  &.hide{
    opacity: 0;
  }
  .scroll {
    display: block;
    width: 3px;
    height: 3px;
    margin: 6px auto;
    border-radius: 4px;
    background: #fff;
    -webkit-animation: finger 1s infinite;
    animation: finger 1s infinite;
  }
}
@-webkit-keyframes finger {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}

@keyframes finger {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}
.space-border {
  width: 30px;
  height: 1px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-bottom: 40px;
  background: $color;
  &:before {
    width: 30px;
    height: 1px;
    content: "";
    position: absolute;
    left: -40px;
    background: rgba(255, 255, 255, .25);
  }
  &:after {
    width: 30px;
    height: 1px;
    content: "";
    position: absolute;
    right: -40px;
    background: rgba(255, 255, 255, .25);
  }
}


.space-border2 {
  width: 50%;
  height: 1px;
  position: relative;
  // margin: 0 auto;
  margin-bottom: 5px;
  margin-bottom: 5px;
  background: $color;
  
  
}
.space-border3{
  width: 50%;
  height: 1px;
  position: relative;
  // margin: 0 auto;
  margin-bottom: 50px;
  // margin-bottom: 5px;
  // background: $color;
  
}

/*menu*/
.navbar-brand{
  cursor: pointer;
  padding-top: 10px;
  z-index: 2;
  .imginit{
    display: block;
  }
  .imgsaly, .imggrey, .imgchef, .imgdesigner, .imglawyer, .imgdark{
    display: none;
  }
}
#header-wrap {
  width: 100%;
  position: fixed;
  z-index: 1001;
  nav.navbar{
    font-family: var(--title-font);
    font-size: 13px;
    font-weight: 600;
    padding: 0;
    
    &.rev{
      padding: 20px 0;
    }
  }
  &.transparent {
    background: none;
  }
  &.sticky{
    nav.navbar{
      background: $black;
    }
  }
}
.navbar-nav{
  display: inline-block !important;
  .nav-item{
    display: inline-block;
    a{
      padding: 30px 18px;
      padding-right: 6px;
      color: $white;
      cursor: pointer;
      &.active{
      color: $color;            
      }
    }
  }
}

blockquote {
  font-family: var(--body-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  border: none;
  // border-left: solid 3px #333333;
  padding-left: 20px;
}


.blockquotewithoupadding {
  font-family: var(--body-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  border: none;
  // border-left: solid 3px #333333;
  
}

.gridblack{
  background: linear-gradient(92deg, #0a1119, #0a121a);
}
.imgdiv{
  position: absolute;
  right: 0;
}

.marginbetween{
  margin-top: 500px;
}
.hightmon{
  height: 1200px !important;
}
.newfuck{
  position: relative;
}
.timerst{
  align-content: center;
    align-items: center;
    align-self: center;
}

.imgdiv2{
  position: absolute;
    bottom: 0;
    right: 0;
}
.imgdiv3{
  position: absolute;
    bottom: 0;
    left: 0;
}

.downloadhedin{
  position: fixed;
  // font-family: Lato;
  font-family: Arial, Helvetica, sans-serif;
  width: 100vw;
  }

.SpotWalletprogress{
  width: 210px;
    padding-left: 70px;
    padding-bottom: 20px;
    margin-top: -5px;
  }

  .SpotWalletprogressAR{
    width: 210px;
      padding-left: 70px;
      padding-bottom: 20px;
    margin-top: -5px;

    }
  
    .horizantalGoogleloginH{
      background-color: #e9e9e9e0;
      box-shadow: #00000073 0px 2px 4px 0px;
        border-radius: 4px;
        height: 40px;
        margin-top: 10px;
        margin-bottom: 10px;
        // padding: 10px;
        display: contents;
       
    }
    .googlenewbutton{
      color: #4d4c4c;
        // box-shadow: #00000073 0px 2px 52px -8px;
        border-radius: 4px;
        height: 40px;
        padding: 10px;
        background-color: #e9e9e9e0;
    }

  .SpotWalletprogressStory{
    width: 200px;
      padding-left: 70px;
      padding-bottom: 20px;
    }


#progressbar{
  width: 830px;
  background-color: #52D3CB;
  border-radius: 8px;
  height: 35px;
  /* (height of inner div) / 2 + padding */
  // padding: 3px;
}
.progressbarinside{
background-image: linear-gradient(to right, #42047e 90%,#52D3CB);

  width: 100%;
  height: 20px;
  border-radius: 6px;
}

#progressbarSpot{
  background-color: #42047e;
  border-radius: 8px;
  // padding: 3px;
  height: 35px;
}

.progressbarinsideSpot{
  height: 20px;
  border-radius: 6px;
}

#progressbarDeriv{
  background-color: #52D3CB;
  border-radius: 8px;
  height: 35px;
  /* (height of inner div) / 2 + padding */
  // padding: 3px;
}

.progressbarinsideDeriv{
// linear-gradient(to right, #42047e 90%,#52D3CB);
background-image: linear-gradient(to right, #42047e 90%,#52D3CB);
// width: 40%;
/* Adjust with JavaScript */
height: 20px;
border-radius: 6px;
}








.newrow{
  display: inline-flex;
    flex-direction: row;
    flex-wrap: inherit;
}

.accountname{
  font-size: 30px;
    color: #00B0F0;
    padding-right: 10px;
}

.maxcontlast{
  width: max-content;
  font-size:60px;
}
.activeshortarabic{
  direction: 'rtl';
  text-align: -webkit-center;
}
.texttier{
  margin-left: 5px;
    margin-right: 5px;
    color: #FFA500;
}
.bar-chart-wrapper {
  height: 210px;
}
.colortexthave2{
  color: crimson !important;
}
.colortexthave{
  color: #00B050 !important;
}


.colortexthaverelax{
  color: #4BD1C6 !important;
  // font-size: 60px;
}
.colortexthaverelax2{
  color: #4BD1C6 !important;
  font-size: 40px;
  margin-left: 10px;
}
// .colortexthaverelax2AR{
//   color: #4BD1C6 !important;
//   font-size: 35px !important;
//   margin-right: 10px;
//   margin-top: -1px;


// }
.colortextmonth{
  color: #ED7D31 !important;
}
.colorRR{
  color: #00B0F0;
}

.newmodal{
  color: #0D101A;
}
.bullscr{
  width: 650px !important ;
}
.hs-responsive-embed-youtube {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  padding-top: 25px;
  }
  .hs-responsive-embed-youtube iframe {
  position: absolute;
  width: 100%!important;
  height: 100%!important;
  }
.aspect-ratio--16x9 {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.vidte{
  position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    // height: 50%;
    // z-index: 1;
    object-fit: cover;
    z-index: -1
}

.justinM{
  justify-content: center;
}

.newshortst{
  font-size: 60px;
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.newfinaltouch{

}

.boujee-text {
  font-size: clamp(3rem, 25vmin, 12rem);
}



// .boujee-text {
//   --bg-size: 300%;
//   --color-one: rgb(43, 43, 43);
//   --color-two: rgb(253, 253, 253);
//   --color-three: rgb(122, 122, 122);

//   // --color-one: rgb(122, 122, 122);
//   // --color-two: rgb(253, 253, 253);
//   // --color-three: rgb(43, 43, 43);

//   font-family: lato;
//   // font-size: clamp(3rem, 25vmin, 8rem);
//   font-size: clamp(50px, 8vw, 60px);

//   background: linear-gradient(
//       90deg,
//       var(--color-one),
//       var(--color-two),
//       var(--color-one)
//     )
//     0 0 / var(--bg-size) 100%;
//   color: transparent;
//   background-clip: text;
// }

.boujee-text {
  --bg-size: 300%;
  --color-one: rgb(43, 43, 43);
  --color-two: rgb(253, 253, 253);
  --color-three: rgb(122, 122, 122);


  font-family: lato;
  // font-size: clamp(3rem, 25vmin, 8rem);
  font-size: clamp(50px, 8vw, 60px);

  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .boujee-text {
    animation: move-bg 10s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}




blockquote.q-big2 {
  color: #8f8f8f;
  font-size: 40px;
  border: none;
  margin: 0;
  padding: 0;
}
.gradient-text {
  background-image: linear-gradient(60deg, #e6e6e6, #9a9791);
  background-clip: text;
  color: transparent;
  font-size: 55px;

}
.textSizeforAnyfirstsec{
  color: #ffffff;
  font-size: 55px;
}
.textSizeforAny{
  color: #8f8f8f;
  font-size: 55px;
}

blockquote.q-big {
  color: #8f8f8f;
  font-size: 55px;
  border: none;
  margin: 0;
  padding: 0;
}
blockquote.q-big .d-big {
  font-size: 150px;
  display: block;
  color: $color;
}
.d-quote-by {
  font-family: var(--body-font);
  display: block;
  font-size: 14px;
  margin-top: 20px;
  border-left: solid 1px rgba(255, 255, 255, .2);
  padding-left: 20px;
  line-height: 1.5em;
}
.icon_quotations::before {
  content: "\7b";
}
.de_3d-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba(34, 34, 34,.0);
  background-size: auto;
  position: relative;
  border-left: solid 1px rgba(255, 255, 255, .2);
  .d-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 40px;
  }
  i {
    position: absolute;
    font-size: 40px;
    top: 38px;
    left: 30px;
    color: $color;
  }
  h3 {
    margin-left: 50px;
    margin-top: 5px;
    margin-bottom: 25px;
  }
}

/*Galery*/
.my-gallery-class{
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
}
.image-element-class {
  position: absolute;
  padding: 0;
  img{
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
.de_modal {
  cursor: pointer;
  .card-image-1 {
    position: relative;
    overflow: hidden;
    margin: 10px;
    transition: .5s;
    outline: none;
    .d-text {
      color: #ffffff;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      padding: 10%;
      transform: scale(1.1);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10%;
      background: rgba(30, 30, 30, 0);
      background-size: auto;
      transition: .5s;
    }
    h3 {
      opacity: 0;
      margin: 0;
      font-size: 22px;
      transition: .5s;
    }
    h5 {
      opacity: 0;
      font-weight: 300;
      font-size: 14px;
      color: #ffffff;
      position: absolute;
      left: 0;
      bottom: 5%;
      width: 100%;
      text-align: center;
      transition: .5s;
    }
    img {
      transform: scale(1.1);
      filter: grayscale(100%);
      transition: .5s;
    }
    &:hover {
      z-index: 1;
      -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
      -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
      box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, .5);
      transition: .5s;
      .d-text {
        transform: scale(1);
        background: rgba(30, 30, 30 , .3);
        background-size: auto;
      }
      h3 {
        opacity: 1;
        transition: .5s;
      }
      h5 {
        opacity: 1;
        bottom: 5%;
        transition: .5s;
      }
      img {
        transform: scale(1);
        filter: grayscale(0);
        transition: .5s;
      }
    }
  }
}
.LightboxGal{
  position: fixed;
  width: 100%;
  height: 100%;
  background: #171A1D;
  top: 0;
  left: 0;
  padding: 80px 0;
  z-index: 9999;
  overflow: auto;
    .closeGal{
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    .button-close {
      display: block;
      position: fixed;
      top: 40px;
      right: 40px;
      width: 48px;
      height: 48px;
      background: none;
      color: #ffffff;
      outline: none;
      border-radius: 30px;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      border: 1px solid rgba(255, 255, 255, .5);
      transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 1px;
        -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        border-left: 8px solid #ffffff;
        border-right: 8px solid #ffffff;
      }
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 1px;
        -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        border-left: 8px solid #ffffff;
        border-right: 8px solid #ffffff;
      }
      &:hover{
        border-color: #ffffff;
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        &:before {
        border-left-width: 15px;
        border-right-width: 15px;
        }
        &:after {
          border-right-width: 15px;
          border-left-width: 15px;
        }
      }
    }
  }
  .mainLightbox{
    width: 100%;
    blockquote {
      position: relative;
      font-weight: 500;
      padding: 0;
      border: none;
      font-size: 22px;
      color: #fff;
      span {
        font-size: 14px;
        margin-top: 10px;
        display: block;
        padding-left: 25px;
        &:before {
          content: '';
          position: absolute;
          width: 20px;
          height: 1px;
          background: #fff;
          left: 0;
          margin-top: 14px;
        }
      }
    }
  }
}
.de_project-details {
  color: #aaaaaa;
  .d-field {
    padding: 5px 0;
    margin-bottom: 5px;
    border-bottom: solid 1px rgba(255, 255, 255, .1);
    i {
      color: #ffffff;
      margin-right: 10px;
    }
  }
  span {
    float: right;
    a{
      text-decoration: none;
      color: $color;
    }
  }
}
.de_project-info{
  h3{
      font-size: 24px;
      margin-bottom: 25px;
  }
  p{
    color: #aaa;
    margin-bottom: 20px;
  }
  .spacer-30 {
    width: 100%;
    height: 30px;
    display: block;
    clear: both;
  }
}

/*resume*/
h3.s_border {
  font-size: 28px;
  margin-bottom: 30px;
}
.d_timeline {
  position: relative;
  counter-reset: list 0;
  padding: 0;
  padding-left: 0px;
  padding-left: 22px;
}
.d_timeline-item {
  list-style: none;
  border-left: 1px solid rgba(255, 255, 255, .2);
  &:last-child{
    border-left: 1px solid rgba(255, 255, 255, 0);
  }
}
.d_timeline-title {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  background: linear-gradient(90deg, rgba(255,255,255,.1) 0%, rgba(255,255,255,0) 100%);
  &:before {
    color: #fff;
    position: relative;
    left: -22px;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    background-color: $color;
    counter-increment: list;
    content: counter(list)
  }
}
.d_timeline-text {
  margin: 0;
  padding: 0 0 40px 40px;
  .d_title {
    font-family: var(--title-font);
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 0px;
    display: block;
    font-size: 18px;
    color: #fff;
  }
  .d_company {
    margin-bottom: 10px;
    display: block;
    color: $color;
    font-size: 16px;
  }
}

/* blog */
.bloglist.item {
  margin-right: 25px;
}
.de_modal {
  cursor: pointer;
  img {
    display: block;
    width: 100%;
    transform: scale(1);
    filter: grayscale(100);
    transition: .5s;
  }
}
.post-image {
  overflow: hidden;
  &:hover img {
    width: 100%;
    margin-bottom: 0px;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%);
    transform: scale(1.1);
    transition: .5s;
    outline: none;
  }
}
.post-text {
  padding-top: 20px;
  h4 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  p{
    color: #aaaaaa;
  }
}
.slick-dots li{
  margin: 0;
}
.slick-dots li button::before{
  font-size: 9px !important;
  color: rgba(255, 255, 255, .3) !important;
  opacity: 1;
}
.slick-dots li.slick-active button::before{
  font-size: 10px !important;
  color: #cf1f1f !important;
}
.blog-read img {
  margin-bottom: 30px;
  &.img-fullwidth {
    width: 100%;
    height: auto;
  }
}
.post-like::before, .post-by::before, .post-comment::before {
  font-family: FontAwesome;
  padding-right: 10px;
  font-size: 11px;
}
.post-like::before {
  content: "\f08a";
}
.post-comment::before {
  content: "\f0e5";
}
.post-date, .post-comment, .post-like, .post-by, .post-author {
  margin-right: 20px;
  font-size: 13px;
  color: $white;
  font-family: var(--body-font);
}
.post-text {
  padding-top: 0;
}
#blog-comment ol {
  padding-left: 40px;
  li {
    list-style: none;
    margin-left: -40px;
    padding-left: 0;
    min-height: 70px;
    line-height: 1.6em;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #eee;
    border-bottom-color: rgba(255, 255, 255, .1);
    .avatar {
      position: absolute;
      margin-top: 5px;
      img{
        width: 80px;
        border-radius: 100%;
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
      }
    }
    .comment-info {
      margin-left: 100px;
      margin-bottom: 5px;
       .c_name {
        display: block;
        font-weight: 700;
        color: #ffffff;
      }
      .c_date {
        font-size: 12px;
        color: $color;
      }
       .c_reply {
        padding-left: 20px;
        margin-left: 20px;
        border-left: solid 1px #ddd;
        border-left-color: rgba(255, 255, 255, .2);
        font-size: 12px;
        color: $color;
      }
    }
    .comment {
      margin-left: 100px;
    }
  }
}

/* contact */



#contact_form textarea {
  height: 168px;
  color: #ffffff;
  border: solid 1px rgba(255, 255, 255, .2);
  background: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  &:focus{
    color: #fff;
    background: rgba(var(--primary-color-rgb), .1);
  }
}
.mt30 {
  margin-top: 30px;
}
.btn-main{
  display: inline-block;
  text-align: center;
  color: #fff;
  background: rgba(var(--primary-color-rgb), 1);
  outline: 0;
  text-decoration: none;
  padding: 4px 40px;
  font-size: 14px;
  border: none;
  font-weight: bold;
  transition: .5s;
  &:hover{
    transition: .5s;
    box-shadow: 2px 2px 20px 0px rgba(var(--primary-color-rgb), 0.5);
  }
}
#success, #failed{
  color: $color;
  margin-top: 30px;
  &.hide{
   display: none;
  }
  &.show{
    display: block;
  }
}

/* to top */
#scroll-to-top {
    cursor: pointer;
    width: max-content;
    height: max-content;
    position: fixed;
    right: 10px;
    z-index: 999;
    &.init{
      bottom: -60px;
      transition: all 0.3s ease;
    }
    &.show{
      bottom: 15px;
      transition: all 0.3s ease;
    }
    div{
      font-size: .8rem;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 30px;
      border-radius: 30px;
      border: solid 1px rgba(255,255,255,.2);
      background: #171A1D;
      transition: all 0.3s ease;
      i{
        color: $white;
        &:before {
          font-family: "FontAwesome";
          font-size: 18px;
          content: "\f106";
          position: relative;
          top: 2px;
        }
      }
      &:hover{
        background: #1b1b1b;
        transform: scale(1.1);
        transition: all 0.3s ease;
      }
    }
}

/* footer */
footer {
  position: relative;
  color: rgba(255, 255, 255, .8);
  padding: 20px 0;
  background: rgba(0, 0, 0, .1);
  border-top: solid 1px rgba(255, 255, 255, .1);
  .social-icons {
    display: block;
    float: right;
  }
   a {
    color: rgba(255, 255, 255, .5);
    text-decoration: none;
      &:hover{
        color: rgba(255, 255, 255, .5);
      }
    i {
      font-size: 16px !important;
      color: #ffffff;
      text-shadow: none;
      padding: 12px 10px 8px 10px;
      width: 34px;
      height: 34px;
      text-align: center;
      border-radius: 30px;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      margin: 0 3px 0 3px;
      transition: .5s;
      &:hover {
        background: #fff;
        border-color: #eceff3;
        color: #333;
        transition: .5s;
      }
      &:before{
        position: relative;
        top: -2px;
      }
    }
  }
}

/* new skills bar */
.d-skill{
  position: relative;
  margin-bottom: 20px;
}
.d-skills-bar .d-bar {
  margin: 25px 0;
}
.d-skills-bar .d-bar .d-info {
  margin-bottom: 8px;
  color: #222222;
}
.d-skills-bar .d-bar .d-info{
    color: #ffffff;
}
.d-skills-bar .d-bar .d-info span {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 8px;
}
.progress-bar{
  overflow: unset;
}
.main-bar{
  position: relative;
  right: 0;
  top: -36px;
  color: #f0f0f0;
  font-size: 12px;
  font-weight: 700;
  background: #202020;
  padding: 1px 8px;
  border-radius: 3px
}

.movetop{
  margin-top: 10%
}

/* profile 8*/
.f-profile {
  margin-bottom: 0px;
  .fp-wrap {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    transition: .5s;
    outline: none;
    .fpw-overlay {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
    }
    .fpwo-wrap {
      width: 100%;
      position: absolute;
      bottom: 30px;
      text-align: center;
    }
    .fpw-overlay-btm {
      opacity: 0;
    }
    .img-fluid {
      max-width: 100%;
      height: auto;
      transition: .5s;
      outline: none;
    }
    &:hover{
      img {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        filter: grayscale(100%);
        transform: scale(1.05);
      }
    }
  }
  h4 {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .fpwow-icons {
    display: inline-block;
    background: #ad8e6d;
    padding: 5px 10px 7px 10px;
    border-radius: 3px;
    a{
      padding: 0 5px;
      cursor: pointer;
      i{
        font-size: 15px;
        color: #fff;
      }
    }
  }
}

/* menu reveal */
#de-menu-reveal {
    float: right;
    width: 36px;
    height: 36px;
    padding: 6px;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 2;
    .lt, 
    .lm, 
    .lb{
        display:block;
        position:absolute;
        width:36px;
        height:2px;
        background:#ffffff;
        transition: .5s;
    }
    .lt{
    top:5px;
    }
    .lm{
        top:15px;
    }
    .lb{
        top:25px;
    }
    &.menu-open .lt{
        transition: .5s;
        top:15px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    &.menu-open .lm{
        width:0;
        opacity:0;
        transition: .5s;
    }
    &.menu-open .lb{
        transition: .5s;
        top:15px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
.mainreveal{
  display: table;
  opacity: 0;
  transform: scale(0);
  background: #191A1C;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: .5s;
  .navbar-nav{
  display: table-cell !important;
  vertical-align: middle;
    li{
      display: block;
      text-align: center;
      margin: 10px 0;
      font-size: 20px;
       a {
        padding: 0;
        font-weight: 400;
      }
    }
  }
  &.show{
    opacity: 1;
    transform: scale(1);
    transition: .5s;
  }
}

video.pop {
  display: block;
  max-width: 100%;
  height: auto;
}
.bullwith{
  display: flex;
}
.bullwith2{
  display: flex;
  direction:rtl;
}
.padleft{
  padding-left: 5px;
}

/*** media all ***/
@media screen and (max-width: 1920px) and (min-width: 1650px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
      max-width: 1640px;
  }
}
@media (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1440px;
    }
    .float-text {
      display: block;
    }
    .mobilemenu{
      display: none;
    }
}
@media only screen and (max-width: 1280px) {
  .float-text {
    display: none;
  }
}

@media only screen and (max-width: 992px){
  .burgermenu{
    display: block;
    z-index: 1;
  }
  .navbar-brand{
    z-index: 1;
  }
  .mobilemenu{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #171A1D;
    padding: 0 0 0 0;
    padding: 90px 20px;
    .navbar-nav .nav-item{
      display: block !important;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      margin: 0;
      padding: 0px 0px;
      width: 100%;
    }
  }
  #header-wrap nav.navbar .navbar-nav .nav-item a{
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-color: rgba(255, 255, 255, .1);
  }
  .dekstopmenu{
    display: none;
  }
  #header-wrap nav.navbar{
    padding: 20px 0;
  }
  .bloglist.item{
    margin-right: 0px;
    padding: 5px;
  }
}

@media only screen and (max-width: 767px){
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .h1, h1.big, .h1_big, h1.ultra-big {
    font-size: 32px;
    line-height: 35px;
  }
  footer{
    text-align: center;
    .social-icons{
      margin: 10px auto;
      float: unset;
    }
  }
  .CircularProgressbar{
    width: 80% !important;
  }
  #hero-area .h1_big{
    font-size: 150% !important;
    line-height: normal !important;
  }
}

.timemove{
  margin-left: -90px;
}
.barchartwid{
  width: 1000px !important;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-left: 200px;
}


.movefor{
  position: relative;
  z-index: 99999;
}



